import {
  HomeHero,
  Stats,
  FanExperience,
  ConnectSection,
  TestimonialSection,
  LoadingScreen,
} from "components";

import { homePageStats } from "jsonObjets/stats";
import { useEffect, useState } from "react";

export default function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading && <LoadingScreen />}
      <HomeHero />
      <Stats stats={homePageStats} />
      <FanExperience />
      <ConnectSection />
      <TestimonialSection />
    </>
  );
}
